<template>
    <div>
        <vs-dialog v-model="customizationDialog">
            <h3>Personalizza i cookie</h3>

            <vs-checkbox v-model="settings.tecnical" disabled>
                Cookie tecnici
            </vs-checkbox>

            <vs-checkbox v-model="settings.analytics_storage">
                Cookie analitici
            </vs-checkbox>

            <vs-checkbox v-model="settings.functionality_storage">
                Cookie funzionali
            </vs-checkbox>

            <vs-checkbox v-model="settings.personalization_storage">
                Cookie di personalizzazione
            </vs-checkbox>

            

            <vs-checkbox v-model="settings.tourial_storage">
                Cookie di tracciamento campagne
            </vs-checkbox>

            <!--
            <vs-checkbox v-model="settings.ad_storage">
                Cookie per la pubblicità
            </vs-checkbox>

            <vs-checkbox v-model="settings.ad_user_data">
                Dati utente per la pubblicità
            </vs-checkbox>

            <vs-checkbox v-model="settings.ad_personalization">
                Personalizzazione della pubblicità
            </vs-checkbox>

            <vs-checkbox v-model="settings.security_storage">
                Cookie di sicurezza
            </vs-checkbox>
            -->

            <vs-button block @click="applyChanges" size="large" style="margin:0!important; margin-top: 20px;">
                Applica
            </vs-button>
        </vs-dialog>
        <div class="wised-cookie-consent animate__animated animate__slideInUp " v-if="visible">
            <div class="wised-cookie-consent__content">
                <div class="wised-cookie-consent__text">
                    <p>
                        Il sito richiede l'installazione di cookie tecnici (necessari per il funzionamento del sito) e cookie analitici per aiutarci ad analizzare l'utilizzo del nostro portale. Cliccando su 'Acconsento' darai il consenso all'utilizzo dei cookie analitici indicati nella <router-link to="/cookie-policy">cookie policy</router-link>. Cliccando su 'Personalizza' potrai proseguire con la personalizzazione dei cookie.
                    </p>
                </div>
                <div class="wised-cookie-consent__buttons">
                    <vs-button border size="large" @click="customize">
                        PERSONALIZZA
                    </vs-button>
                    <vs-button size="large" @click="acceptAll">
                        ACCONSENTO
                    </vs-button>
                </div>
            </div>
        </div>
        <div v-else class="wised-cookie-consent__settings animate__animated animate__slideInLeft" :class="{'no-menu': $route.meta.hiddenMenu}" @click="visible = true">
            Gestisci Cookies
        </div>
</div>
</template>

<script>
import 'animate.css';
export default {
    name: "WisedCookieConsent",

    data() {
        return {
            visible: false,

            settings: {
                tecnical: true,

                // GOOGLE ANALYTICS 4
                ad_storage: false,
                ad_user_data: false,
                ad_personalization: false,
                analytics_storage: true,
                functionality_storage: true,
                personalization_storage: true,
                security_storage: false,

                // TOURIAL
                tourial_storage: true, // campaign tracking
            },

            customizationDialog: false
        }
    },

    methods: {
        acceptAll() {
            this.settings.tourial_storage = true;
            this.settings.analytics_storage = true;
            this.settings.functionality_storage = true;
            this.settings.personalization_storage = true;
            
            this.applyChanges();
        },

        customize(){
            this.customizationDialog = true;
        },

        applyChanges(){
            // store settings as CSV in cookie
            let settings = '';
            for (let key in this.settings) {
                if (this.settings[key]) {
                    settings += key + ',';
                }
            }

            if(this.$IS_APP){
                localStorage.setItem('TOURIAL-X', settings);
            } else {
                this.$cookie.set('TOURIAL-X', settings, 180);
            }
            this.customizationDialog = false;
            this.visible = false;

            this.handleAnalytics();
        },

        handleAnalytics(){
            if(this.settings.tourial_storage && this.$route.query.i) { // accepted cookies before and opened a campaign url now
                // website opened from a campaign and has the query param i = campaign id
                this.$cookie.set('TOURIAL-C', this.$route.query.i, 180);
            }

            window.gtag('consent', 'update', {
                ad_storage: this.settings.ad_storage ? 'granted' : 'denied',
                ad_user_data:  this.settings.ad_user_data ? 'granted' : 'denied',
                ad_personalization:  this.settings.ad_personalization ? 'granted' : 'denied',
                analytics_storage:  this.settings.analytics_storage ? 'granted' : 'denied',
                functionality_storage:  this.settings.functionality_storage ? 'granted' : 'denied',
                personalization_storage:  this.settings.personalization_storage ? 'granted' : 'denied',
                security_storage:  this.settings.security_storage ? 'granted' : 'denied',
            });
        }
    },

    mounted() {
        if(this.$IS_APP){ // managed by Apple Transparency Consent and Control
            return;
        }

        if(this.$route.meta.print) return; // don't show on print pages
        setTimeout(() => {
            let settingsData = null;
            if(this.$IS_APP){
                settingsData = localStorage.getItem('TOURIAL-X');
            } else {
                settingsData = this.$cookie.get('TOURIAL-X');
            }


            if (settingsData == null) { // never accepted or declined
                this.visible = true;
            } else {
                // deserialize settings
                let settings = settingsData.split(',');
                for (let key in this.settings) {
                    if (settings.includes(key)) {
                        this.settings[key] = true;
                    } else {
                        this.settings[key] = false;
                    }
                }

                this.handleAnalytics();

            }
        }, 2000)

    }
}
</script>

<style>
.wised-cookie-consent__settings {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding: 0.5rem;
    color: var(--primary);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    text-align: left;
    cursor: pointer;
    border-top-right-radius: 10px;
    font-size: 0.8rem;
}

.wised-cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.wised-cookie-consent__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wised-cookie-consent__text {
    font-size: 0.9rem;
}

.wised-cookie-consent__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.wised-cookie-consent__buttons button {
    white-space: nowrap;
}

/* MOBILE */
@media (max-width: 600px) {
    .wised-cookie-consent__text {
        padding: 0;
        width: 100%;
    }

    .wised-cookie-consent__settings {
        bottom: 70px;
        box-shadow: none;
        background: rgba(255,255,255, 0.7);
        font-size: 0.7rem;
        padding: 0.2rem;
        padding-right: 0.3rem;
    }

    .wised-cookie-consent__settings.no-menu {
        bottom: 0!important;
    }

    .wised-cookie-consent__content {
        flex-direction: column;
        padding: 0.5rem
    }

    .wised-cookie-consent__buttons {
        flex-direction: column;
        width: 100%;
    }
    .wised-cookie-consent__buttons button {
        width: 100%;
        margin: 0;
    }
}
</style>
